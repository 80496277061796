<template>
    <div id="courseTeaching">
        <!-- <div style="position: fixed;right: 5%;top: 50%;z-index: 6;">
                    <el-button type="primary" size="mini"  style="background-color: #76C8F6;border: none;">返回</el-button>
                    <el-button type="primary" size="mini"  style="background-color: #76C8F6;border: none;" >保存</el-button>
                    <el-button type="primary" size="mini"  style="background-color: #76C8F6;border: none;">发布</el-button>
           </div> -->
           <div style="position:fixed;right:10%;bottom:28%;">
                <div  @click="blackClick" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                    <img :src="require('@/assets/img/ConstructionDetail/back.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;" >返回</span>
                </div>
                <div @click="cligocenterCUN()" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                    <img :src="require('@/assets/img/ConstructionDetail/baocun.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;">保存</span>
                </div>
                <!-- <div @click="cligocenterFA()" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;margin-bottom: 15px;">
                    <img :src="require('@/assets/img/ConstructionDetail/fabu.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;">发布</span>
                </div> -->
            </div>
        <div class="box">
         
          <div class="boxOne">
           
            <div style="width: 100%;height: 100%;margin: 10px;display: flex;justify-content:flex-start">
                <div style="float:left;display:flex;">
                    <img style="width:100%;object-fit: contain;" :src="prctureUrl" alt="" srcset="">
                </div>
                <div style="margin-left: 30px;margin-top:20px;float:left">
                    <p style="font-weight: bold;">平台信息
                    </p>
                    <p style="font-size: 14px;line-height: 40px;">
                        <span style="color: #666666;">语言：</span><span>{{ systemLanguage }}</span>
                        <span style="color: #666666;padding-left: 20px;">系统发布时间：</span><span>{{ publishTime.split(' ')[0] }}</span>
                    </p>
                    <p style="font-weight: bold;">适配终端</p>
                    <p style="font-size: 14px;line-height: 40px;">
                       <span>{{ Adaptiveterminal }}</span>
                    </p>
                    <p style="font-weight: bold;">权属信息</p>
                    <p style="font-size: 14px;line-height: 40px;">
                        <span style="color: #666666;">版权所有人：</span><span>{{ copyrightOwner }}</span>
                        <span style="color: #666666;padding-left: 20px;">来源组织：</span><span>{{ affiliatedOrganization }}</span>
                    </p>
                    <p style="font-weight: bold;">应用属性</p>
                    <p style="font-size: 14px;line-height: 40px;">
                        <span style="color: #666666;">适用专业：</span><span>{{ majorName }}</span>
                        <span style="color: #666666;padding-left: 20px;">适用行业：</span><span>{{ industryName }}</span>
                    </p>
                </div>
                
                <div style="float: right;margin: 20px;">
                    <span style="font-size: 14px;">选择平台：</span>
                <el-select v-model="platformValue" class="m-2" :placeholder="请选择" size="mini" @change="platformChange">
                <el-option
                v-for="item in platform"
                :key="item.id"
                :label="item.systemName"
                :value="item.id"
                :disabled="item.status == 1?false:true"
                />
            </el-select>
                </div>
            </div>
           
          </div>
            <div class="boxTwo">
                <span style="font-size: 14px;margin-left: 20px;">系统实训名称：</span>
                <el-input v-model="systemExperienceName" placeholder="请输入" clearable style="width: 200px;" size="mini" />
                <span style="font-size: 14px;margin-left: 20px;">所属阶段：</span>
            <el-select v-model="stageValue" class="m-2" placeholder="请选择" size="mini">
                <el-option v-for="item in stageData" :key="item.id" :label="item.stageName"
                                    :value="item.id" />
            </el-select>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-top:30px;margin-left:20px;" @click="peizhiClick">配置习题</el-button>
            </div>
           
            <div class="boxFive">
                <p style="padding: 10px;">
                    <span style="font-size: 18px;font-weight: 600;padding-right: 10px;">平台介绍</span>
                    <!-- <el-input style="width: 200px;padding-right: 10px;" size="mini" v-model="input" placeholder="请输入栏目名称" /> -->
                    <!-- <el-button type="primary" plain size="mini">修改栏目名称</el-button> -->
                </p>
                <div style="width: 100%;border-top: 1px solid #F7F8FA;padding: 10px;">
                    <el-input
                    style="width: 98%;"
                    v-model="latformIntroduction"
                    :autosize="{ minRows: 10, maxRows: 10 }"
                    type="textarea"
                    placeholder=""
                />
                </div>
            </div>
            <div class="boxFive">
                <p style="padding: 10px;">
                    <span style="font-size: 18px;font-weight: 600;padding-right: 10px;">实训目标</span>
                    <!-- <el-input style="width: 200px;padding-right: 10px;" size="mini" v-model="input" placeholder="请输入栏目名称" /> -->
                    <!-- <el-button type="primary" plain size="mini">修改栏目名称</el-button> -->
                </p>
                <div style="width: 100%;border-top: 1px solid #F7F8FA;padding: 10px;">
                    <el-input
                    style="width: 98%;"
                    v-model="trainingObjective"
                    :autosize="{ minRows: 10, maxRows: 10 }"
                    type="textarea"
                    placeholder=""
                />
                </div>
            </div>
            <div class="boxFive">
                <p style="padding: 10px;">
                    <span style="font-size: 18px;font-weight: 600;padding-right: 10px;">操作流程</span>
                    <!-- <el-input style="width: 200px;padding-right: 10px;" size="mini" v-model="input" placeholder="请输入栏目名称" /> -->
                    <!-- <el-button type="primary" plain size="mini">修改栏目名称</el-button> -->
                </p>
                <div style="width: 100%;border-top: 1px solid #F7F8FA;padding: 10px;">
                    <el-input
                    style="width: 98%;"
                    v-model="operationProcess"
                    :autosize="{ minRows: 10, maxRows: 10 }"
                    type="textarea"
                    placeholder=""
                />
                </div>
            </div>
            <div class="boxFive">
                <p style="padding: 10px;">
                    <span style="font-size: 18px;font-weight: 600;padding-right: 10px;">使用手册</span>
                    <!-- <el-input style="width: 200px;padding-right: 10px;" size="mini" v-model="input" placeholder="请输入栏目名称" /> -->
                    <!-- <el-button type="primary" plain size="mini">修改栏目名称</el-button> -->
                </p>
                <div style="width: 100%;border-top: 1px solid #F7F8FA;padding: 10px;">
                    <el-input
                    style="width: 98%;"
                    v-model="userManual"
                    :autosize="{ minRows: 10, maxRows: 10 }"
                    type="textarea"
                    placeholder=""
                />
                </div>
            </div>

                
        </div>
        <div >
            <el-dialog
            
            v-model="dialogVisible"
            title="配置习题"
            width="75%"
            :before-close="handleClose">
            <el-form v-if="dialogVisible" :inline="true" :model="formInline" ref="formInlineRef"  :rules='rulesexercises' class="demo-form-inline" style="margin-left: 20px;">
                <el-form-item label="习题名称" prop="exercisesnName">
                    <el-input  size="mini" v-model="formInline.exercisesnName" placeholder="请填写习题名称" clearable />
                </el-form-item>
                <div v-for="(item,index) in formInline.domains2" :key="index">
                <el-form-item label="系统名称" :prop="'domains2.'+index+'.system'" :rules="rulesexercises.system">
                    <el-select
                        size="mini"
                        @change="systemChange(index)"
                        v-model="item.system"
                        placeholder="请选择系统名称"
                        clearable>
                        <el-option v-for="item in systemOption" :key="item.id" :label="item.systemName" :disabled="item.status == 1?false:true"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="模块名称" :prop="'domains2.'+index+'.module'" :rules="rulesexercises.module">
                    <el-select
                    @change="moduleChange(index)"
                    @visible-change="visibleChange(index)"
                        size="mini"
                        v-model="item.module"
                        placeholder="请选择模块名称"
                        clearable>
                        <el-option v-for="item in moduleOption" :key="item.id" :label="item.moduleName"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题型" :prop="'domains2.'+index+'.tixing'" :rules="rulesexercises.tixing">
                    <el-select
                    @change="tixingChange(index)"
                    size="mini"
                        v-model="item.tixing"
                        placeholder="请选择题型"
                        clearable>
                        <el-option
                        v-for="item in tixingOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题库难度" :prop="'domains2.'+index+'.nandu'" :rules="rulesexercises.nandu">
                    <el-select
                    @change="nanduChange(index)"
                    size="mini"
                        v-model="item.nandu"
                        placeholder="请选择题库难度"
                        clearable>
                        <el-option
                        v-for="item in nanduOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="">当前题库符合条件试题数：{{ item.numTest }}</el-form-item>
                <el-form-item label="填写试题数量" :prop="'domains2.'+index+'.stNum'" :rules="rulesexercises.stNum">
                    <el-input  size="mini" v-model="item.stNum" @blur="stNumBlur" placeholder="请填写试题数量" clearable @input="stNumInput(item)" />
                </el-form-item>
                <span type="primary"  style="cursor: pointer;" @click="delete2Click(index)">X</span>
                </div>

                <el-button type="primary" round style="background-color: #76C8F6;border: none;" @click="addFrom">新增</el-button>
            </el-form>
         
            <p style="font-weight: 600;padding: 20px;">配置规则</p>
            <el-radio-group v-model="formInline.radio1" class="ml-4" style="margin-left: 20px;" @change="radioChange">
                <el-radio label="1" size="large">随机组卷</el-radio>
                <el-radio label="2" size="large">统一试卷</el-radio>
            </el-radio-group>
            <p v-if="formInline.radio1 == 1" style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*随机组卷为基于配置规则及试题数量，随机抽取试题，每个学生的试卷不完全一样</p>
            <p v-else style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*统一试卷为系统基于配置规则，生成试卷，可在预览后进行确认，每个学生的试卷完全一样</p>
            <el-button  v-if="formInline.radio1 == 2" style="margin: 20px;" type="primary" plain @click="yulanClick" size="mini">试卷预览</el-button>
            <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button  @click="handleClose">取消</el-button>
                <el-button style="background-color: #76C8F6;border: none;" type="primary" @click="insterClick">
                确定
                </el-button>
            </div>
            </template>
        </el-dialog>
        </div>
        


        <el-dialog
            v-model="examPreview"
            title="试卷预览"
            width="75%"
            :show-close="false"
            :before-close="examPreviewClose">
            <div class="system">
                <p>
                <span style="padding-right: 20px;">类型:实训习题</span>
                <span style="padding-right: 20px;">系统名称:{{ platformName?platformName:'--' }}</span>
                <span style="padding-right: 20px;">试题名称:{{ formInline.exercisesnName?formInline.exercisesnName:'--' }}</span>
               </p>
               <div style="width: 100%;height: 700px;overflow-y: auto;">
                   <p style="font-weight: 600;" v-if="tiankongList.length>0">填空题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(item,index) in tiankongList" :key="index+'index'">
                     <div v-if="item.topicType == 3">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ index+1 }}</span> {{ item.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="tiankongQuestions(item,index)"
                                     >替换试题</el-button>
                                 </p>
                         </div>
                         
                     </div>
                   </div>
                  
                  
                  
                   <p style="font-weight: 600;padding-top: 10px;" v-if="danxuanList.length>0">单选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(danxuan,indexdx) in danxuanList" :key="indexdx+'indexdx'">
                     <div v-if="danxuan.topicType == 1">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexdx+1 }}</span> {{ danxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duanxuanQuestions(danxuan,indexdx)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group>
                                 <el-radio  v-for="(item1,index1) in danxuan.answers" :key="index1" :label="false" >{{item1.isOrder == 1?'A':item1.isOrder == 2?'B':item1.isOrder == 3?'C':item1.isOrder == 4?'D':''}} {{ item1.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
                  

                   <p style="font-weight: 600;padding-top: 10px;" v-if="duoxuanList.length>0">多选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(duoxuan,indexduoxuan) in duoxuanList" :key="indexduoxuan+'indexduoxuan'">
                     <div v-if="duoxuan.topicType == 2">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexduoxuan+1 }}</span> {{ duoxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duoxuanQuestions(duoxuan,indexduoxuan)"
                                     >替换试题</el-button>
                                 </p>
                                 <el-radio-group>
                                     <el-radio  v-for="(item3,index4) in duoxuan.answers" :key="index4+'index4'" :label="false" >{{item3.isOrder == 1?'A':item3.isOrder == 2?'B':item3.isOrder == 3?'C':item3.isOrder == 4?'D':''}} {{ item3.content }}</el-radio>
                                 </el-radio-group>
                             
                         </div>
                         
                     </div>
                   </div>


                   <p style="font-weight: 600;padding-top: 10px;" v-if="panduanList.length>0">判断题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(itempanduan,indexpanduan) in panduanList" :key="indexpanduan+'indexindexpanduan'">
                     <div v-if="itempanduan.topicType == 4">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexpanduan+1 }}</span> {{ itempanduan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="panduanQuestions(itempanduan,indexpanduan)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group>
                                 <el-radio  v-for="(item5,index2) in itempanduan.answers" :key="index2" :label="false" >{{item5.isOrder == 1?'A':item5.isOrder == 2?'B':item5.isOrder == 3?'C':item5.isOrder == 4?'D':''}} {{ item5.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
               </div>
    
               <div style="text-align: center;" class="dialog-footer">
                 
                   <el-button type="primary" style="background-color: #76C8F6;border: none;margin-bottom: 20px;" round @click="confirmClick">
                   确认试题
                   </el-button>
               </div>
            
               
           </div>
        </el-dialog>
        
        <el-dialog
        title="替换试题"
        v-model="replaceShow" width="50%"  
        :before-close="replaceShowClose">
         <div class="searchList">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="系统名称">
                    <el-select
                        size="mini"
                        @change="systemChange2(index)"
                        v-model="xitongValue"
                        placeholder="请选择系统名称"
                        clearable>
                        <el-option v-for="item in systemOption" :key="item.id" :label="item.systemName"
                                    :value="item.id" :disabled="item.status == 1?false:true" />
                    </el-select>
                </el-form-item>
                <el-form-item label="模块名称">
                    <el-select
                        size="mini"
                        v-model="moduleValue"
                        placeholder="请选择模块名称"
                        clearable>
                        <el-option v-for="item in moduleOption" :key="item.id" :label="item.moduleName"
                                    :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="选择题库难度">
                    <el-select
                    size="mini"
                        v-model="nanduValue"
                        placeholder="请选择题库难度"
                        clearable>
                        <el-option
                        v-for="item in nanduOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
              <el-form-item label="上传人:">
                <el-input size="mini" v-model="upDataName" placeholder="请输入上传人" clearable />
              </el-form-item>
              <el-form-item label="题干:">
                <el-input size="mini" v-model="stemData" placeholder="请输入题干" clearable />
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary"  @click="onSubmit">搜索</el-button>
              </el-form-item>
             
            
            </el-form>
            <div class="searchButton">
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;">添加题库</el-button> -->

            </div>
          </div>
          <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="394px">
            <el-table-column fixed type="index"  label="序号" />

            <el-table-column prop="systemName" label="系统名称"  />
            <el-table-column prop="moduleName" label="模块名称" />
    
            <el-table-column prop="chapters" label="难度"  width="110" >
              <template #default="scope">
            <span type="text">{{ scope.row.topicDifficulty == 1?'简单':scope.row.topicDifficulty == 2?'中等':'困难' }}</span>
          </template>
            </el-table-column>
            <el-table-column prop="testQuestionContent" label="题干" width="150" />
            <el-table-column prop="correctAnswer" label="正确答案" width="150" />
            <el-table-column prop="testQuestionAnalysis" label="解析" width="150" />
            <el-table-column prop="insertTime" label="上传时间" width="200" />
            <el-table-column prop="realName" label="上传人" />
            <el-table-column  label="操作" fixed="right" width="160" >
              <template #default="scope">
                  <el-button type="text" @click="deselect(scope.row)" v-if="testQuestionId != scope.row.oldId && oldId != scope.row.oldId">{{scope.row.select == 1?'已选择':'选择'}}</el-button>
                  <span v-else>已选择</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
          <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="total"  @current-change="handleCurrentChange" />
            </div>
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="cancellation">确定</el-button>
        </div>
        
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,onActivated, ref} from "vue";
import {get_select_trainingSystem,get_select_trainingSystemModule,get_select_questionList,f_update_systemExperience} from '@/js/teachingSquare'
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl,baseUrl} from "@/js/util"
import * as echarts from 'echarts';
import {get_select_stageList} from '@/js/homeListApi'
import { get_topicCount,f_intsert_generateCoursePreviewPapers,f_get_systemExperienceExercises,f_update_replaceQuestions} from '@/js/classManagement'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    setup () {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const formInlineRef = ref(null);
        const validatestNum = (rule,value,callback)=>{
      /*console.log(rule);
      console.log(value);
      console.log(callback);*/
      if (!value){
        callback(new Error('请输入试题数量'));
      }
      //使用正则表达式进行验证是否为数字
      if (/\D/g.test(value)){
        callback(new Error('请输入正确格式'));
      }
      callback()
    };
        const state = reactive({
            value:'',
            oldId:'',
            selectvalue:'',
            selectvalue2:'',
            xitongValue:'',
            systemOption:[],
            moduleOption:[],
            tableData:[],
            latformIntroduction:'',
            trainingObjective:'',
            operationProcess:'',
            userManual:'',
            systemExperienceName:'',
            examPreview:false,
            baseUrl:baseUrl,
            prctureUrl:"",
            systemLanguage:getStorage('platformRow').systemLanguage,
            publishTime:getStorage('platformRow').publishTime,
            Adaptiveterminal:getStorage('platformRow').Adaptiveterminal,
            copyrightOwner:getStorage('platformRow').copyrightOwner,
            affiliatedOrganization:getStorage('platformRow').affiliatedOrganization,
            majorName:getStorage('platformRow').majorName,
            industryName:getStorage('platformRow').industryName,
            stageValue:'',
            stageData:[],
            options:[],
            danxuanList:[],
            duoxuanList:[],
            tiankongList:[],
            panduanList:[],
            examinationPaperId:'',
            questionsIsoder:'',
            replaceShow:false,
            systemValue:'',
            moduleValue:'',
            nanduValue:'',
            upDataName:'',
            stemData:'',
            titleName:'',
            tixingOptions: [
                {
                value: 1,
                label: "单选",
                },
                {
                value: 2,
                label: "多选",
                },
                {
                value: 3,
                label: "填空",
                },
                {
                value: 4,
                label: "判断",
                },
            ],
            nanduOptions: [
                {
                value: 1,
                label: "简单",
                },
                {
                value: 2,
                label: "中等",
                },
                {
                value: 3,
                label: "困难",
                },
            ],
            
            input:'',
            dialogVisible:false,
            platformValue:route.query.platformValue,
            platformName:'',
            platform:[],
            questionsArray:[],
            questionsList:[],
            topicType:'',
            total:0,
            currentPage: 1,
            pageSize: 5,
            testQuestionId:'',
            questionsRow:{},
            questionsIndex:'',
            formInline:{
                exercisesnName:'',
                radio1:"1",
                domains2:[
                {
                    system:'',
                    module:'',
                    tixing:'',
                    nandu:'',
                    stNum:'',
                    numTest:0,
                },
            ],
            },

            
                rulesexercises:{
                exercisesnName:[{ required: true,message:'请输入习题名称',trigger: 'blur' }],
                system:[{ required: true,message:'请选择系统名称',trigger: 'change' }],
                module:[{ required: true,message:'请选择模块',trigger: 'change' }],
                tixing:[{ required: true,message:'请选择题型',trigger: 'change' }],
                nandu:[{ required: true,message:'请选择难度',trigger: 'change' }],
                stNum:[{ validator:validatestNum, trigger: 'blur' }],
            },})
        onMounted(() => {
            methods.stageList();
           methods.get_select_trainingSystem();
           if (route.query.title == '编辑') {
                            state.systemExperienceName = getStorage('platformRow').systemExperienceName;
                            state.stageValue = getStorage('platformRow').stageId;
                            state.latformIntroduction = getStorage('platformRow').introduce;
                            state.trainingObjective = getStorage('platformRow').target;
                            state.operationProcess = getStorage('platformRow').operationProcedure;
                            state.userManual = getStorage('platformRow').serviceManual;
                            state.majorName = getStorage('platformRow').majorName;
                            state.industryName = getStorage('platformRow').industryName;
                            methods.f_get_systemExperienceExercises();
           }
           let url = (getStorage('platformRow').prctureUrl)?(getStorage('platformRow').prctureUrl):(getStorage('platformRow').pictureUrl)?(getStorage('platformRow').pictureUrl.filePath):null;
           state.prctureUrl = state.baseUrl+'/'+url;
        })
        const methods ={
            platformChange(event){
                console.log(event);
                if (event) {
                state.platform.forEach(element => {
                    if (event == element.id) {
                        setStorage('platformRow',element);  
                            state.prctureUrl=state.baseUrl+'/'+getStorage('platformRow').prctureUrl;
                            console.log(state.prctureUrl);
                            state.systemLanguage=getStorage('platformRow').systemLanguage;
                            state.publishTime=getStorage('platformRow').publishTime;
                            state.Adaptiveterminal=getStorage('platformRow').Adaptiveterminal;
                            state.copyrightOwner=getStorage('platformRow').copyrightOwner;
                            state.affiliatedOrganization=getStorage('platformRow').affiliatedOrganization;
                            state.majorName=getStorage('platformRow').majorName;
                            state.industryName=getStorage('platformRow').industryName;
                    }
                });
            }
            },
            stNumInput(item){
                console.log(item);
                if (item.stNum > item.numTest) {
                    ElMessage({
                                    message: "试题数量不能超过题库数量",
                                    type: "error",
                                    showClose: true,
                                    });
                     item.stNum = 0;
                }
            },
            insterClick(){
                if (state.formInline.radio1 == 2) {
                    if (state.titleName == '修改') {
                    return ElMessage({
                                    message: "请重新生成试卷",
                                    type: "info",
                                    showClose: true,
                                    });
                }
                }
                
                formInlineRef.value.validate((valid) => {
                    if (valid) {
                        state.dialogVisible = false;
                    }
                })
                
            },
            // h编辑回填习题
            f_get_systemExperienceExercises(){
                let params = {
                    systemExperienceId:getStorage('platformRow').id
                }
                f_get_systemExperienceExercises(params).then(res=>{
                    if (res.data.code == 1) {
                        console.log(res.data);
                        state.formInline.exercisesnName =res.data.data.courseExercises.exercisesnName;
                        state.formInline.radio1 = res.data.data.courseExercises.generativeRule.toString();
                        state.formInline.id = res.data.data.courseExercises.id;
                        state.formInline.trainingType = res.data.data.courseExercises.trainingType;
                        state.formInline.domains2 = [];
                        res.data.data.examinationGenerativeRuleList.forEach((element,index) => {
                            state.formInline.domains2.push({
                                system:element.trainingSystemId,
                                module:element.trainingSystemModuleId,
                                tixing:element.topicType,
                                nandu:element.topicDifficulty,
                                stNum:element.topicCount,
                                id:element.id
                            })
                            // state.formInline.domains2[index].system = element.trainingSystemId;
                            // state.formInline.domains2[index].module = element.trainingSystemModuleId;
                            // state.formInline.domains2[index].tixing = element.topicType;
                            // state.formInline.domains2[index].nandu = element.topicDifficulty;
                            // state.formInline.domains2[index].stNum = element.topicCount;
                            // state.formInline.domains2[index].id = element.id;
                            methods.get_select_trainingSystemModule(state.formInline.domains2[index].system);
                            methods.get_topicCount(
                            state.formInline.domains2[index].tixing,
                            state.formInline.domains2[index].nandu,
                            state.formInline.domains2[index].system,
                            state.formInline.domains2[index].module,   
                            index
                        );
                        });
                       if (res.data.data.examinationPaper) {
                        state.questionsList = res.data.data.examinationPaper.questions;
                        state.examinationPaperId = res.data.data.examinationPaper.id;
                       }
                        
                        state.danxuanList = [];
                        state.duoxuanList = [];
                        state.tiankongList = [];
                        state.panduanList = [];
                        state.questionsList.forEach(element => {
                            console.log(element[0]);
                            element.forEach(element2 => {
                                if (element2.topicType == 1) {
                                    element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                        state.danxuanList.push(element2);
                                        state.danxuanList.forEach(element => {
                                        element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                    });
                                        console.log(state.danxuanList);
                                    }else if(element2.topicType == 2){
                                        element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                        state.duoxuanList.push(element2);
                                        state.duoxuanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                    }else if(element2.topicType == 3){
                                        element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                        state.tiankongList.push(element2)
                                        state.tiankongList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                    }else if(element2.topicType == 4){
                                        element2.answers = element2.examinationPaperAnswers;
                                    delete element2.examinationPaperAnswers;
                                        state.panduanList.push(element2)
                                        state.panduanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                        console.log(state.panduanList);
                                    }
                            });
                                       
                                    
                                    
                                });
                       
                        
                    }
                })
            },
            // 保存
            cligocenterCUN(){
                if (!state.systemExperienceName || !state.stageValue || !state.latformIntroduction || !state.trainingObjective || !state.operationProcess || !state.userManual) {
                    return ElMessage({
                                type: 'info',
                                message: '请完善信息',
                                })
                }
                if (state.formInline.exercisesnName == '') {
                    return ElMessage({
                                type: 'info',
                                message: '请配置习题',
                                })
                }
                if (state.formInline.radio1 == 2) {
                    if (state.questionsList.length == 0) {
                        return ElMessage({
                                type: 'info',
                                message: '请预览试卷',
                                })
                    }
                }
                let i_trainingExercises = {};
                let params = {};
                if (route.query.title == '编辑') {
                    state.questionsList = [];
                    state.questionsList = state.questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
                    i_trainingExercises.exercisesnName = state.formInline.exercisesnName;
                i_trainingExercises.trainingType = 2;
                i_trainingExercises.generativeRule = state.formInline.radio1;
                i_trainingExercises.id = state.formInline.id;
                i_trainingExercises.trainingGenerativeRules = [];
                state.formInline.domains2.forEach(element => {
                    i_trainingExercises.trainingGenerativeRules.push({
                        trainingSystemId:element.system,
                        trainingSystemModuleId:element.module,
                        topicType:element.tixing,
                        topicDifficulty:element.nandu,
                        topicCount:element.stNum,
                        id:element.id
                    })
                });
                i_trainingExercises.questions = state.formInline.radio1 == 1?[] : state.questionsList;
                 params = {
                    trainingSystemId:state.platformValue,
                    stageId:state.stageValue,
                    systemExperienceName:state.systemExperienceName,
                    introduce:state.latformIntroduction,
                    target:state.trainingObjective,
                    operationProcedure:state.operationProcess,
                    serviceManual:state.userManual,
                    userId:getStorage('userId'),
                    i_trainingExercises:i_trainingExercises,
                    id:getStorage('platformRow').id
                }
                }else{
                i_trainingExercises.exercisesnName = state.formInline.exercisesnName;
                i_trainingExercises.trainingType = 2;
                i_trainingExercises.generativeRule = state.formInline.radio1;
                i_trainingExercises.trainingGenerativeRules = [];
                state.formInline.domains2.forEach(element => {
                    i_trainingExercises.trainingGenerativeRules.push({
                        trainingSystemId:element.system,
                        trainingSystemModuleId:element.module,
                        topicType:element.tixing,
                        topicDifficulty:element.nandu,
                        topicCount:element.stNum
                    })
                });
                i_trainingExercises.questions = state.formInline.radio1 == 1?[] : state.questionsList;
                 params = {
                    trainingSystemId:state.platformValue,
                    stageId:state.stageValue,
                    systemExperienceName:state.systemExperienceName,
                    introduce:state.latformIntroduction,
                    target:state.trainingObjective,
                    operationProcedure:state.operationProcess,
                    serviceManual:state.userManual,
                    userId:getStorage('userId'),
                    i_trainingExercises:i_trainingExercises,
                    
                }
                }
               
               
                console.log(params);
                f_update_systemExperience(params).then(res=>{
                    if (res.data.code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            state.platformValue = '';
                            state.systemExperienceName = '';
                            state.stageValue = '';
                            state.latformIntroduction = '';
                            state.trainingObjective = '';
                            state.operationProcess = '';
                            state.userManual = '';
                            state.formInline = {
                                exercisesnName:'',
                                radio1:"1",
                                domains2:[
                                {
                                    system:'',
                                    module:'',
                                    tixing:'',
                                    nandu:'',
                                    stNum:'',
                                    numTest:0,
                                },
                            ],
                         };
                         router.go(-1)
                    }else{
                        ElMessage({
                            type: 'error',
                            message: res.data.message,
                            })
                    }
                })
            },
            // 发布
            cligocenterFA(){
                
            },
            // 确认
            confirmClick(){
                state.titleName = '';
            state.questionsList = [];
            console.log(state.danxuanList,state.questionsList);
            state.questionsList = state.questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
            state.examPreview = false;
            console.log(state.questionsList);
        },
                // 单选替换试题
        duanxuanQuestions(item,index){
            console.log(item,index);
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 多选替换试题
        duoxuanQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 填空替换试题
        tiankongQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        // 判断替换试题
        panduanQuestions(item,index){
            state.replaceShow = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
            state.questionsIndex = index;
            state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.xitongValue = state.systemOption[0].id;
            state.moduleValue = state.moduleOption[0].id;
            state.nanduValue = state.nanduOptions[0].value;
            methods.get_select_questionList();
        },
        systemChange2(){
            methods.get_select_trainingSystemModule(state.xitongValue)
        },
        handleCurrentChange(val){
            state.currentPage = val;
            methods.get_select_questionList();
        },
         // 获取试题列表
         get_select_questionList(){
            let params = {
                trainingSystemId:state.xitongValue,
                trainingSystemModuleId:state.moduleValue,
                questionBankType:2,
                topicDifficulty:state.nanduValue,
                pageIndex:state.currentPage,
                pageSize:state.pageSize,
                content:state.stemData,
                topicType:state.topicType,
                userName:state.upDataName,
            }
            get_select_questionList(params).then(res=>{
                if (res.data.code == 1) {
                    state.tableData = res.data.data.list;
                    state.total = res.data.data.totalCount.Value;
                    if (state.tableData.length>0) {
                        state.tableData.forEach(element => {
                        element.answers.forEach(item => {
                            if (item.isAnswer) {
                                element.correctAnswer = item.content;
                            }
                        });
                    });
                    }
                    
                }
            })
        },
                // 操作选择
            deselect(row){
                state.tableData.forEach(element => {
                    element.select = 0;
                });
                row.select = 1;
                state.questionsRow = row;
            },
                          // 确认
      cancellation() {
        console.log(state.questionsRow);
        if (route.query.title == '编辑') {
                 let params = {
                examinationPaperId:state.examinationPaperId,
                oldQuestionsId:state.oldId,
                newQuestionsId:state.questionsRow.oldId,
                isOrder:state.questionsIsoder
            }
            f_update_replaceQuestions(params).then(res=>{
                if (res.data.code == 1) {
                    const item = res.data.data;
                    if (item.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = item.id;
                state.danxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.danxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = item.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = item.id;
                state.duoxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = item.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 3){
                state.tiankongList[state.questionsIndex].id = item.id;
                state.tiankongList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.tiankongList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = item.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 4){
                state.panduanList[state.questionsIndex].id = item.id;
                state.panduanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.panduanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = item.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }else{
                    if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }
                state.replaceShow = false;
            })
        }else{
            let obj = {};
        if (Object.keys(state.questionsRow).length>0) {
          if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
        }else{
            console.log(1111);
        }
        state.replaceShow = false;
        }
       

      },
        replaceShowClose(){
            state.replaceShow = false
        },
            examPreviewClose(){
                state.examPreview = false;
            },
             // 获取当前符合条件的试题数
             get_topicCount(
                        topicType,
                        topicDifficulty,
                        courseChapterId,
                        courseSectionId,
                        index
                    ) {
                        if (
                        topicType &&
                        topicDifficulty &&
                        courseChapterId &&
                        courseSectionId
                        ) {
                        let params = {
                            topicType: topicType,
                            topicDifficulty: topicDifficulty,
                            questionBankType: 2,
                            trainingSystemId: courseChapterId,
                            trainingSystemModuleId: courseSectionId,
                        };
                        get_topicCount(params).then((res) => {
                            if (res.data.code == 1) {
                            state.formInline.domains2[index].numTest = res.data.data;
                            }
                        });
                        }
                    },
            // 获取系统下拉数据
            get_select_trainingSystem(){
            get_select_trainingSystem().then(res=>{
                if (res.data.code == 1) {
                    state.platform = res.data.data;
                    state.systemOption = res.data.data;
                   
                }
                
            })
        },
        // 系统
        systemChange(index){
            state.formInline.domains2[index].module = '';
            state.moduleOption = [];
            methods.get_select_trainingSystemModule(state.formInline.domains2[index].system);
            methods.get_topicCount(
                            state.formInline.domains2[index].tixing,
                            state.formInline.domains2[index].nandu,
                            state.formInline.domains2[index].system,
                            state.formInline.domains2[index].module,   
                            index
                        );
                        state.titleName = '修改';
        },
        visibleChange(index){
            methods.get_select_trainingSystemModule(state.formInline.domains2[index].system);
        },
        // 模块
        moduleChange(index){
            methods.get_topicCount(
                            state.formInline.domains2[index].tixing,
                            state.formInline.domains2[index].nandu,
                            state.formInline.domains2[index].system,
                            state.formInline.domains2[index].module,   
                            index
                        );
                        state.titleName = '修改';
        },
        // 题型
        tixingChange(index){
            methods.get_topicCount(
                            state.formInline.domains2[index].tixing,
                            state.formInline.domains2[index].nandu,
                            state.formInline.domains2[index].system,
                            state.formInline.domains2[index].module,   
                            index
                        );
                        state.titleName = '修改';
        },
        // 难度
        nanduChange(index){
            methods.get_topicCount(
                            state.formInline.domains2[index].tixing,
                            state.formInline.domains2[index].nandu,
                            state.formInline.domains2[index].system,
                            state.formInline.domains2[index].module,   
                            index
                        );
                        state.titleName = '修改';
        },
        // 
        stNumBlur(){
            state.titleName = '修改';
        },
        onSubmit(){
            state.currentPage = 1;
            methods.get_select_questionList();
        },
        // 获取系统下模块数据
        get_select_trainingSystemModule(event){
            let params = {
                trainingSystemId:event
            }
            get_select_trainingSystemModule(params).then(res=>{
                if (res.data.code == 1) {
                    state.moduleOption = res.data.data;
                }
            })
        },
         //所属阶段
         stageList(){
                get_select_stageList().then(res=>{
                    const {status,data}=res
                    if(status == 200){
                        state.stageData = data.data
                    }
                })
            },
            blackClick(){
                state.platformValue = '';
                            state.systemExperienceName = '';
                            state.stageValue = '';
                            state.latformIntroduction = '';
                            state.trainingObjective = '';
                            state.operationProcess = '';
                            state.userManual = '';
                state.formInline = {
                                exercisesnName:'',
                                radio1:"1",
                                domains2:[
                                {
                                    system:'',
                                    module:'',
                                    tixing:'',
                                    nandu:'',
                                    stNum:'',
                                    numTest:0,
                                },
                            ],
                         };
                router.go(-1)
            },
            delete2Click(index){
                state.formInline.domains2.splice(index,1);
                state.titleName = '修改';
            },
            addFrom(){
                state.formInline.domains2.push( {
                    system:'',
                    module:'',
                    tixing:'',
                    nandu:'',
                    stNum:'',
                    numTest:0,
                },)
            },
            radioChange(event){
                if (event == 2) {
                    state.titleName = '修改';
                }
            },
            yulanClick(){
                let kechengtArr = state.platform.filter((item) => {
                    return item.id === state.platformValue;
                  });
                  console.log(kechengtArr);
                  state.platformName = kechengtArr[0].systemName;
                if ( state.titleName == '修改') {
                    methods.f_intsert_generateCoursePreviewPapers();
                }else{
                    state.examPreview = true;
                    // state.danxuanList = [];
                    //     state.duoxuanList = [];
                    //     state.tiankongList = [];
                    //     state.panduanList = [];
                    //     state.questionsList.forEach(element => {
                    //         console.log(element[0]);
                    //         element.forEach(element2 => {
                    //             if (element2.topicType == 1) {
                    //                 element2.answers = element2.examinationPaperAnswers;
                    //                 delete element2.examinationPaperAnswers;
                    //                     state.danxuanList.push(element2);
                    //                     console.log(state.danxuanList);
                    //                 }else if(element2.topicType == 2){
                    //                     element2.answers = element2.examinationPaperAnswers;
                    //                 delete element2.examinationPaperAnswers;
                    //                     state.duoxuanList.push(element2);
                    //                 }else if(element2.topicType == 3){
                    //                     element2.answers = element2.examinationPaperAnswers;
                    //                 delete element2.examinationPaperAnswers;
                    //                     state.tiankongList.push(element2)
                    //                 }else if(element2.topicType == 4){
                    //                     element2.answers = element2.examinationPaperAnswers;
                    //                 delete element2.examinationPaperAnswers;
                    //                     state.panduanList.push(element2)
                    //                     console.log(state.panduanList);
                    //                 }
                    //         });
                                       
                                    
                                    
                    //             });
                }
                
                // router.push({path:'/home/testPaperPreview',query:{platformValue:state.platformValue}})
            },
            // 生成试卷
            f_intsert_generateCoursePreviewPapers(){
               
                let arraylist = [];
                       
                             state.formInline.domains2.forEach((element) => {
                            arraylist.push({
                                trainingSystemId: element.system,
                                trainingSystemModuleId: element.module,
                                topicType: element.tixing,
                                topicDifficulty: element.nandu,
                                topicCount: element.stNum*1,
                            });
                            });
                        
                       
                        f_intsert_generateCoursePreviewPapers(arraylist).then(res=>{
                            if (res.data.code == 1) {
                                state.questionsArray = res.data.data;
                                state.questionsList = [];
                                state.danxuanList = [];
                                state.duoxuanList = [];
                                state.tiankongList = [];
                                state.panduanList = [];
                                state.questionsArray.forEach((element) => {
                                    element.questions.forEach((item) => {
                                    state.questionsList.push(item);
                                    });
                                });
                                // setStorage("questionsList", state.questionsList);
                                state.questionsList.forEach(element => {
                                        if (element.topicType == 1) {
                                        state.danxuanList.push(element);
                                        state.danxuanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                        console.log(state.danxuanList);
                                    }else if(element.topicType == 2){
                                        state.duoxuanList.push(element);
                                        state.duoxuanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                    }else if(element.topicType == 3){
                                        state.tiankongList.push(element)
                                        state.tiankongList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                    }else{
                                        state.panduanList.push(element)
                                        state.panduanList.forEach(element => {
                                            element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                                        });
                                        console.log(state.panduanList);
                                    }
                                    
                                    
                                });
                                state.examPreview = true;
                            }else{
                                ElMessage({
                                type: 'error',
                                message: res.data.message,
                                })
                            }
                        })
            },
            peizhiClick(){
                state.dialogVisible = true;
                // state.formInline.domains2 = [{
                //     system:'',
                //     module:'',
                //     tixing:'',
                //     nandu:'',
                //     stNum:'',
                //     numTest:0,
                // }]
               
            },
            handleClose(){
                state.dialogVisible = false;
    



            },
        }
        
        return {
      ...toRefs(state),
      ...methods,
      formInlineRef
    };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
       .boxOne{
        width: 100%;
        height: 300px;
        background: #fff;
        margin-top: 10px;
       }
       .boxTwo{
        width: 100%;
        height: 100px;
        background: #fff;
        margin-top: 10px;
       }
       .boxThree{
        width: 100%;
        height: 100px;
        background: #fff;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
       }
       .boxFive{
        width: 100%;
        height: 300px;
        background: #fff;
        margin-top: 10px;
        margin-bottom:10px ;
       }
    }
   
}
</style>